import TYPES from '@/types';
import Vue from 'vue';

// Application
import GetCustomGoalTypesQuery
  from '@/modules/flagship/catalogs/custom-goal-types/application/queries/get-custom-goal-types-query';
import UpdateInvestorProfileOnCustomGoalCommand
  from '@/modules/flagship/investor-profile/custom-goal/application/commands/update-investor-profile-on-custom-goal-command';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

// Domain
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';
import {
  CustomGoalTypesEntity,
} from '@/modules/flagship/catalogs/custom-goal-types/domain/entities/custom-goal-types-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipProfilingStepGoalsViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.GET_CUSTOM_GOAL_TYPES_QUERY)
  private readonly get_custom_goal_types_query!: GetCustomGoalTypesQuery;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_ON_CUSTOM_GOAL_COMMAND)
  private readonly update_custom_goal_command!: UpdateInvestorProfileOnCustomGoalCommand;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship_profiling_step_goals';

  private readonly view!: Vue;

  goal_types: CustomGoalTypesEntity[] = [];

  goal_types_selected: string[] = [];

  goal_text = '';

  is_disabled = true;

  show_another_goal = false;

  private investor_profile_entity: InvestorProfileEntity = {
    id: '',
    customer_id: sessionStorage.getItem('user_id')!,
    custom_goals: {},
    is_showed: false,
    person_id: '',
    employment_situation_id: '',
    recommended_investment_product_id: '',
    emergency_fund_availability_id: '',
    has_emergency_fund: true,
    net_monthly_expenses: 0,
    elder_dependent_count: 0,
    is_completed: true,
    child_dependent_count: 0,
    pet_dependent_count: 0,
    net_monthly_income: 0,
    active_goals_count: 0,
    goals_completed: false,
    emergency_fund_locations: {},
    retirement_fund_locations: {},
    exit_pool: {},
    finance_related_job: false,
    income_behavior_in_following_year_id: '',
    emergency_fund_amount: 0,
    has_retirement_fund: null,
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  onGoalTypesSelectedChange = () => {
    this.show_another_goal = this.goal_types_selected.includes('other');
    this.is_disabled = ((this.goal_types_selected.includes('other') && this.goal_text === '')
      || this.goal_types_selected.length === 0);
  }

  setCustomGoalTypes = (custom_goals: Array<CustomGoalTypesEntity>) => {
    const a_custom_goal = custom_goals.filter(
      (item) => this.goal_types_selected.includes(item.name),
    ).map((custom_goal) => {
      if (custom_goal.name === 'other') {
        // eslint-disable-next-line no-param-reassign
        custom_goal.label = this.goal_text;
      }
      return custom_goal;
    });

    this.investor_profile_entity.custom_goals = {
      values: a_custom_goal,
    };
  }

  updateInformation = async () => {
    try {
      this.view.$emit('loadingStep', true);

      this.setCustomGoalTypes(this.goal_types);
      await this.update_custom_goal_command.execute(this.investor_profile_entity);

      this.view.$emit('nextStep');

      return true;
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.update_profile'),
      );
      return false;
    } finally {
      this.view.$emit('loadingStep', false);
    }
  }

  loadCustomGoalTypes = async () => {
    try {
      this.goal_types = await this.get_custom_goal_types_query.execute();
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_custom_goal_options'),
      );
    }
  }

  loadCustomGoals = (custom_goals: Record<string, any>) => {
    if ('values' in custom_goals) {
      custom_goals.values.forEach((custom_goal: any) => {
        this.goal_types_selected.push(custom_goal.name);
        if (custom_goal.name === 'other') {
          this.show_another_goal = true;
          this.goal_text = custom_goal.label;
        }
      });
      this.is_disabled = custom_goals.values.length === 0;
    }
  }

  loadInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute(false);
      this.investor_profile_entity.id = investor_profile.id;
      await this.loadCustomGoals(investor_profile.custom_goals);
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_investor_profile'),
      );
    }
  }

  initialize = async () => {
    this.view.$emit('loadingStep', true);
    await this.loadCustomGoalTypes();
    await this.loadInvestorProfile();
    this.view.$emit('loadingStep', false);
  }
}
